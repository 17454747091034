import React from 'react';
import cruise1 from '../resources/images/cruise/cruise.webp';
import cruise2 from '../resources/images/cruise/cruise-2.webp';
import cruise3 from '../resources/images/cruise/cruise-3.webp';
import img1 from '../resources/images/cruise-img/cl-1.png';
import img2 from '../resources/images/cruise-img/cl-2.png';
import img3 from '../resources/images/cruise-img/cl-3.png';
import img4 from '../resources/images/cruise-img/cl-4.png';
import img5 from '../resources/images/cruise-img/cl-5.png';
import img6 from '../resources/images/cruise-img/cl-6.png';
import img7 from '../resources/images/cruise-img/cl-7.png';
import img8 from '../resources/images/cruise-img/cl-8.png';
import img9 from '../resources/images/cruise-img/cl-9.png';
import img10 from '../resources/images/cruise-img/cl-10.png';
import img21 from '../resources/images/cruise-img/cl-21.png';
import img12 from '../resources/images/cruise-img/cl-12.png';
import img13 from '../resources/images/cruise-img/cl-13.png';
import img22 from '../resources/images/cruise-img/cl-22.png';
import img15 from '../resources/images/cruise-img/cl-15.png';
import img16 from '../resources/images/cruise-img/cl-16.png';
import img17 from '../resources/images/cruise-img/cl-17.png';
import img18 from '../resources/images/cruise-img/cl-18.png';
import img19 from '../resources/images/cruise-img/cl-19.png';
import img20 from '../resources/images/cruise-img/cl-20.png';
import img11 from '../resources/images/cruise-img/cl-20.png';
import img14 from '../resources/images/cruise-img/cl-20.png';
import small from '../resources/images/cruise/small-icons-02.png';
import small2 from '../resources/images/cruise/small-icons-03.png';
import small3 from '../resources/images/cruise/small-icons-04.png';
import '../resources/css/cruise/styles.css';
import '../resources/css/bootstrap.min.css';
// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
// import { Carousel } from 'react-responsive-carousel';
import Carousel from 'react-bootstrap/Carousel';
import Footer from '../components/Footer/footer';

export default function Cruise() {
  return (
    <div>
      <div id="wrapper-content">
        {/* <!-- MAIN CONTENT--> */}
        <div class="main-content">
            
           
            <section class="mt-150">
                <div id="myCarousel" class="carousel slide" data-ride="carousel">                                                  
                    {/* <!-- Wrapper for slides --> */}
                    <div class="carousel-inner">
                    <Carousel fade>
                        <Carousel.Item>
                      <div class="item active" /*style={{marginTop: '50px'}}*/>
                        <img src={cruise1} alt="Los Angeles" style={{width: '100%'}} />
                        {/* <!-- <div class="carousel-caption">
                          <h3>Los Angeles</h3>
                          <p>LA is always so much fun!</p>
                        </div> --> */}
                      </div>
                      </Carousel.Item>
                      <Carousel.Item>
                      <div class="item" /*style={{marginTop: '50px'}}*/>
                        <img src={cruise2} alt="Chicago" style={{width: '100%'}} />
                        {/* <!-- <div class="carousel-caption">
                          <h3>Chicago</h3>
                          <p>Thank you, Chicago!</p>
                        </div> --> */}
                      </div>
                      </Carousel.Item>
                      <Carousel.Item>
                      <div class="item" /*style={{marginTop: '50px'}}*/>
                        <img src={cruise3} alt="New York" style={{width: '100%'}} />
                        {/* <!-- <div class="carousel-caption">
                          <h3>New York</h3>
                          <p>We love the Big Apple!</p>
                        </div> --> */}
                      </div>
                      </Carousel.Item>
                      </Carousel>
                  
                    </div>
                
                    {/* <!-- Left and right controls --> */}
                    {/* <a class="left carousel-control" href="#myCarousel" data-slide="prev">
                      <span class="glyphicon glyphicon-chevron-left"></span>
                      <span class="sr-only">Previous</span>
                    </a>
                    <a class="right carousel-control" href="#myCarousel" data-slide="next">
                      <span class="glyphicon glyphicon-chevron-right"></span>
                      <span class="sr-only">Next</span>
                    </a> */}
                  </div>                
            </section>

           

            <div class="clearfix"></div>
            <section class="grey-bkg" style={{padding: '20px 0'}}>
                <div class="container pt-30 pb-30">
                    <div class="col-md-12 col-xs-12">
                        <div class="wpb_wrapper">
                            <h2 style={{color: '#FF3C00', fontSize: '26px'}}>Cheap Cruise Deals- Find the Cheapest Cruise Deal</h2>
                            <p class="text-justify">
                            Set sail for less with RawFares, your source for fantastic cruise deals across top cruise lines like Viking Cruises and Celebrity Cruise Line. Whether you're seeking the refined elegance of Viking's ocean and river journeys or the luxurious amenities of Celebrity's WiFi-equipped ships and panoramic-view suites, we are offering competitive prices and expert guidance on cheap cruise deals.
                                </p>

                           
                            <p class="text-justify">
                            And when consider sailing with our fam, Disney Cruises provide a magical experience with themed entertainment and kid-friendly activities, while MSC Cruises' Children Cruise Free promotion adds value to family vacations. We specialize in transparency and customer satisfaction, ensuring you find the best cruise deals for your 2024 & 2025 travel plans. Book your next maritime adventure with us and enjoy unparalleled savings, exceptional service, and unforgettable moments at sea. Reach out to us for more details. </p>
                            
                            
                            
                            
                        </div>
                    </div>
                </div>
                </section>


        </div>

        {/* <!-- MAIN CONTENT--> */}

    </div>
    <section>
    <div class="container pt-30 pb-30">
                <h3 class="text-center">Top Cruise Lines</h3>
                <div class="clients-container">
                    <div class="clients">
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="100">
                            <img class="img01" src={img1} />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="200">
                            <img src={img2} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="300">
                            <img src={img3} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="400">
                            <img src={img4} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="500">
                            <img src={img5} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="600">
                            <img src={img6} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="700">
                            <img src={img7} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="800">
                            <img src={img8} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="100">
                            <img src={img9} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="200">
                            <img src={img10} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="300">
                            <img src={img21} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="400">
                            <img src={img12} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="500">
                            <img src={img13} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="600">
                            <img src={img22} class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="700">
                            <img src={img15} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="800">
                            <img src={img16} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="100">
                            <img src={img17} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="200">
                            <img src={img18} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="300">
                            <img src={img19} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="400">
                            <img src={img20} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="500">
                            <img src={img11} alt="" class="img01" />
                        </div>
                        <div class="client-image object-non-visible animated object-visible fadeIn" data-animation-effect="fadeIn" data-effect-delay="600">
                            <img src={img14} alt="" class="img01" />
                        </div>
    
                    </div>
                </div>
    
            </div>
            </section>
            <section>
            <div class="fullfield bkg-theme-blue pt-30 pb-30">
                <div class="container whenfixmar">
                    <div class="row">
                    <div class="col-md-4 col-xs-12 text-center">
                        <p>
                            <img src={small} />
                        </p>
                        <h4 class="txt-white text-center" style={{color: 'white'}}>Competitive Prices</h4>
                        <p class="txt-white" style={{color: 'white'}}>We always aim to give you the best prices.</p>
                    </div>
                    <div class="col-md-4 col-xs-12 text-center">
                        <p>
                            <img src={small2} />
                        </p>
                        <h4 class="txt-white text-center" style={{color: 'white'}}>Safe &amp; Secure Booking</h4>
                        <p class="txt-white" style={{color: 'white'}}>Book with confidence.</p>
                    </div>
                    <div class="col-md-4 col-xs-12 text-center">
                        <p>
                            <img src={small3} />
                        </p>
                        <h4 class="txt-white text-center" style={{color: 'white'}}>Customer Service 24 Hrs</h4>
                        <p class="txt-white" style={{color: 'white'}}>Travel advisors are available to help 24 hours a day.</p>
                    </div>
                    </div>
                </div>
            </div>
    
        </section>
        <Footer dealType={'Cruise'} />
    </div>
  )
}
