import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import MetaTitle from './metaTitle';
import '../resources/css/disclaimer.css';
import Footer from '../components/Footer/footer';
import SITE_CONFIG from '../SiteController/SiteController';
//import background from "../resources/images/search_banner/Homepage-Main-banner.png";

export default function Disclaimer() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  return (
    <>
    {/* <Helmet>
        <title>About us - RawFares</title>
        <meta name="description" content="This is About page of www.rawfares.com" />
        {/* Other meta tags 
        <link rel="canonical" href={`${apiIP}/about`} />
        {/* Additional meta tags for homepage 
      </Helmet> */}
    <MetaTitle pageTitle='Disclaimer - RawFares' pageDescription="RawFares takes pride in being your gateway to major destinations and the off-the-beaten-path gems that await your discovery." url={`${apiIP}/disclaimer`} />
    <div class="container-fluid" /*style={{marginTop: '75px'}}*/>
			
			<div class="row">
			<div class="content_page_title1" style={{marginTop: '75px'}}>
			<div class="container">
			<h1>Disclaimer for RawFares</h1>
			</div>
			</div>
				
		<div class="textBox">
        <div class="container">
          <div class="row">
			<div class="content-wrap">
                <h2>Accuracy of Information</h2>
                <p>RawFares strives to provide accurate and current information on our website. However, as we depend
on travel partners and suppliers, the details provided may change frequently. While we make every
effort to make sure that the information is up-to-date, we cannot vouch for its accuracy or
completeness at all times. Flight prices and availability are subject to change without prior notice due to
the dynamic nature of the travel industry.</p>
                <h2>Website Responsibility</h2>
                <p> RawFares is not liable for any damages, losses, or issues that arise from reliance on the information
presented on our site or any external links provided. We encourage users to verify details with relevant
travel providers or through direct communication with us.</p>
                <h3>Discount Policy</h3>
                <p>Discounts offered by RawFares may vary based on market conditions, including booking dates, travel
periods, and special restrictions. Changes to fares may occur without notice. If you wish to claim a
discount, we will assess your request thoroughly before processing it.</p>
				
				<div class="clearfix"></div>
				
				{/* <div class="right_image">
					<img src="resources/images/about-img1.png" style={{width: '100%'}} alt="" />
				  </div> */}
                <h3>Price Match Guarantee</h3> 
                 <p> When booking with RawFares, we aim to offer competitive rates and an efficient booking experience. If
you find a lower price (including taxes and fees) for the same travel arrangements on a major
competitor’s site within 24 hours of your booking, we will match that price. You can choose to either
receive a refund for the price difference or cancel your booking for a full refund.</p> 
                <h2>How to Request a Price Match</h2>
                 <p><ol>
                    <li>Defining 'Same Itinerary': The itinerary must match precisely, including flight numbers, airlines, dates,
                    seat types, and the number of passengers.</li>
                    <li>What &#39;Available for Booking&#39; Means: The competitor&#39;s itinerary must be live and available for public
                    booking at the time of your price match request.</li>
                    <li>How to Submit a Price Match Request: Call us at (+1-888-415-1536) with your booking details and the lower price
information. Provide the exact URL or screenshots for verification. Incomplete or unverifiable
information may result in denial of the claim.</li>
                    </ol></p>
				  
				  <h2>Exceptions to the Price Match Guarantee</h2>
                <p>
                Our Price Match Guarantee does not apply to certain fares, including membership or rewards program
fares, group bookings, corporate discounts, opaque tickets, auction-based fares, or fare errors.</p>
                <h3>Promo Codes</h3>
                <p>RawFares may offer promo codes for online flight bookings. These codes are subject to the following
                conditions:</p>
                <p><ol>
                    <li>Receiving Promo Codes: Sign up for our newsletters to get promo codes via email.</li>
                    <li>Maximum Discount: Promo codes provide discounts up to the value of our service fees (up to
                        70%).</li>
                        <li>Non-Transferable: Promo codes cannot be transferred or sold and hold no cash value.</li>
                        <li>Application of Codes: Enter the promo code on the payment page to apply the discount. If you
                        experience technical issues, we cannot retroactively apply the discount.</li>
                        <li>Code Validity: We may revise or withdraw promo codes at any time without prior notice. Once
                        withdrawn, promo codes will no longer be accepted.</li>
                        <li>Technical Issues: We are not responsible for technical issues affecting promo code application.
                        You may choose not to proceed with the purchase if the code is not accepted.</li>
                        <li>Final Pricing: The final price displayed (with or without a promo code) is your billed amount. No
                        additional discounts will be applied post-purchase.</li>
                        <li>Combination with Other Offers: Promo codes may be used with other offers unless stated
                        otherwise.</li>
                    </ol></p>
				  
				 
                <p>
                RawFares reserves the right to refuse any transactions involving incorrect promo code values or other
discrepancies even after the booking process is completed. If you need further details or clarifications,
give us a call at (+1-888-415-1536) at any hour of the day, we will be happy to help you out.</p>
                
              </div>	
          </div>
        </div>
      </div>
				
			
				
		      </div>
				
			</div> 
      <Footer dealType={'Flights'} />
      </>
  )
}