/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SITE_CONFIG from '../SiteController/SiteController';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "../resources/css/airline-style.css";
import background from '../resources/images/air-deals/img-airlines.jpg'
import backTab from "../resources/images/tabsbg.png";
import tabsArrow from "../resources/images/tabsarow.png";
import Arror from '../resources/images/icons/arowimg.png'
import Searchbar from "../Shared/Searchbar";
import Footer from "../components/Footer/footer";
import IMG1 from '../resources/images/footertop1.png';
import IMG2 from '../resources/images/footertop2.png';
import IMG3 from '../resources/images/footertop3.png';
import UA from '../resources/images/airline/United-Airlines.png';
import NK from '../resources/images/airline/spirit-Airlines.png';
import F9 from '../resources/images/airline/frontier-airlines.png';
import DealsGlobe from '../resources/images/globepine.png'
import footerBottom from '../resources/images/footerbottom.png'
import MetaTitle from "./metaTitle";

function formatDateAndTime(isoDate) {
  const dateOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = new Date(isoDate);
  const formattedDateString = new Intl.DateTimeFormat(
    "en-US",
    dateOptions
  ).format(formattedDate);
  const formattedTimeString = new Intl.DateTimeFormat(
    "en-US",
    timeOptions
  ).format(formattedDate);

  return { formattedDateString, formattedTimeString };
}

export default function DealsDetails(event) {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  
  const location = useLocation();
 
  const { response1, response2 } = location.state || {};
 


const { airlineName } = useParams();

const parts = airlineName.split('-');

// Remove any undefined or empty parts
const filteredParts = parts.filter(Boolean);

// Get the last part as the Page Value
const pageValue = filteredParts.pop() || '';
// Get the second last part as the URL
// const url = filteredParts.slice(-1).join('-') || '';
const url = airlineName.substring(airlineName.lastIndexOf('/') + 1) || '';

const [firstResponseData, setFirstResponseData] = useState(response1?.response[0] || null);
const [secondResponseData, setSecondResponseData] = useState(response2 || null);

const [reloadData, setReloadData] = useState({
  pageType: "Deals",
  pageValue: "flight",
  siteId: siteID,
  url: url
});

   const fetchData = async () => {
    
 console.log(reloadData)
     try {
       let response;
       if (!response1) {
         response = await axios.post(`${apiIP}:9090/api/Airlines-Details`, reloadData);
         // Set firstResponseData state with the response if response1 is null
         if (response.status === 200) {
           setFirstResponseData(response.data.response[0]);
           console.log("Response from the first API:", response.data);
           ////////console.log(deals)
           const response2 = await axios.post(`${apiIP}:9191/api/Airlines-Routes`,
           { froCity:"",
           toCity:"",
           airline: "flight",
           cabinClass:"Y",
           tripType:"2",
           page:"",
           currency:"USD",
           siteId: siteID,
           departDate:"",
           countryName:"US",
           countryCode:"US",
           cityName:"" }
           );
           setSecondResponseData(response2.data);
        
         }
            
            
       } else {
         // Use the existing response1 if available
         setFirstResponseData(response1.response[0]);
       }
      
       // Update state with response data if needed
     } catch (error) {
       // Handle errors appropriately
     }
   };

   useEffect(() => {
     // Check if response1 or response2 is null, then call fetchData
     if (!response1 || !response2) {
       fetchData();
     }
   }, [url, response1, response2]);
   
   const [selectedFlight, setSelectedFlight] = useState();
   useEffect(() => {
    if (secondResponseData && secondResponseData.response.length > 0) {
      setSelectedFlight(secondResponseData.response[0]);
    }
  }, [secondResponseData]);
  const handleFroCityClick = (item) => {
    setSelectedFlight(item);
  };
     const imageNames = ['business-class.webp','domestic.webp', 'honeymoon.webp', 'international.webp', 'last-minute.webp', 'lgbtq.webp', 'military.webp', 'senior.webp', 'student.webp', 'summer.webp'];
     const filteredImages = imageNames
     .filter(name => name !== `${url}.webp`)
     .slice(0, 3); // Limiting to 3 images

     const imageNameToAirline = {
      'business-class.webp': 'business-class',
      'domestic.webp': 'domestic',
      'honeymoon.webp': 'honeymoon',
      'international.webp': 'international',
      'last-minute.webp': 'last-minute',
      'lgbtq.webp': 'lgbtq',
      'military.webp': 'military',
      'senior.webp': 'senior',
      'student.webp': 'student',
      'summer.webp': 'summer'
    };
    
    
    // Now, in your handleSideClick function, use this mapping to get the airline name corresponding to the clicked image
    const handleSideClick = (pageValue, imageName) => {
      const airlineName = imageNameToAirline[imageName];
      if (airlineName) {
        setReloadData({
          ...reloadData,
          pageValue,
          url: airlineName,
        });
        //fetchData();
        handleAirlineClicked();
      }
    };
    

    const getDealCode = (name) => {
      // Define a mapping of airline names to their respective codes
      const airlineCodes = {
         'Business Class': 'business-class',
         'Domestic': 'domestic',
         'Honeymoon': 'honeymoon',
         'International Travel': 'international',
         'Last Minute': 'last-minute',
         'LGBTQ': 'lgbtq',
         'Military': 'military',
         'Senior': 'senior',
         'Student': 'student',
         'Summer': 'summer'
        // Add more airline names and codes as needed
      };
    
      return airlineCodes[name];
    };
    
    // Function to create a URL with the airline code appended to the airline name
    const createURL = (name) => {
      // console.log(name)
      const airlineCode = getDealCode(name);
      return `https://www.rawfares.com/deals/${airlineCode.toLowerCase()}-flight`;
    };

    const createURLIMG = (name) => {
      
      // Remove the '.webp' extension
      const nameWithoutExtension = name.replace('.webp', '');
      // console.log(nameWithoutExtension);
      // const airlineCode = getDealCode(nameWithoutExtension);
      return `https://www.rawfares.com/deals/${nameWithoutExtension.toLowerCase()}-flight`;
    };

    const [airlines, setAirlines] = useState({
      pageType: "deals",
      pageValue: "flight",
      siteId: siteID,
      url: ""
    });

    const [deals, setDeals] = useState({
      froCity:"",
      toCity:"",
      airline: airlines.pageValue,
      cabinClass:"Y",
      tripType:"2",
      page:"",
      currency:"USD",
      siteId: siteID,
      departDate:"",
      countryName:"US",
      countryCode:"US",
      cityName:""
  });
    const [responseData, setResponseData] = useState(null);
    //const navigate = useNavigate();
    useEffect(() => {
      // Use a useEffect to make the API call whenever airlines state changes
      if (airlines.pageValue && airlines.url) {
        handleAirlineClicked();
      }
    }, [airlines]);
const handleAirlineClicked = async () => {

  const navigationPath = airlines.pageValue.length === 2
    ? `/deals/${airlines.url}-${airlines.pageValue}`
    : `/deals/${airlines.url}-${airlines.pageValue}`;

  const newTab = window.open(navigationPath, '_blank');
  if (newTab) {
      ////////console.log('Received data in new tab:');
    newTab.onload = function() {
      newTab.postMessage(window.location.origin);
    };
  } else {
    ////////console.error('Failed to open new tab. Please check your browser settings or extensions.');
  }

  // Navigate to the new page with data passed via state
  //navigate(navigationPath);
};
    
    
  
    const handleCardClick = (pageType, pageValue, url) => {
      // Set the new values for airlines based on the card clicked
      setAirlines({
        ...airlines,
        pageType,
        pageValue : "flight",
        url,
      });
    };

    const openLink = (url) => {
  window.open(url, '_blank');
  };
  const standardizeAirlineName = (name) => {
    // Define a mapping of variations to a standard name
    const nameVariations = {
      'business-class': 'Business Class',
      'domestic': 'Domestic',
      'honeymoon': 'Honeymoon',
      'international': 'International Travel',
      'last-minute': 'Last Minute',
      'lgbtq': 'LGBTQ',
      'military': 'Military',
      'senior': 'Senior',
      'student': 'Student',
      'summer': 'Summer'
      // Add more variations as needed
    };
  
    return nameVariations[name] || name; // If a variation is found, return the standardized name, otherwise return the original name
  };
  const getAirlineCode = (name) => {
    // Define a mapping of airline names to their respective codes
    const airlineCodes = {
      "Albuquerque": "ABQ",
      "Barcelona": "BCN",
      "Boston": "BOS",
      "Cancun": "CUN",
      "Charleston": "CHS",
      "Hawaii": "HI",
      "Chicago": "ORD",
      "Las Vegas": "LAS",
      "Orlando": "MCO",
      "Seattle": "SEA",
      "San Jose": "SJC",
      "San Francisco": "SFO",
      "Dallas": "DFW",
      "Denver": "DEN",
      "Honolulu": "HNL",
      "Kahului": "OGG",
      "Los Angeles": "LAX",
      "Guadalajara": "GDL",
      "London": "LON",
      "Cuncun": "CUN",
      "New York": "NYC",
      "Paris": "PAR",
      "Toronto": "YYZ",
      "Rome" : "ROM",
      "Tokyo" : "HND",
      "Florida" : "FL",
      "Vancouver" : "YVR",
      "Sydney" : "SYD",
      "South Island": "CHC",
      "Newport" : "ONP",
      "Louisville": "SDF",
      "Washington" : "WAS",
      "Westchester": "HPN"
    };
  
    return airlineCodes[name];
  };

  const createURLNew = (name) => {
    const airlineCode = getAirlineCode(name);
    return `https://www.rawfares.com/flights/cheap-flights-to-${name?.toLowerCase().replace(/\s+/g, '-')}-${airlineCode?.toLowerCase()}`;
  };

  const createURLRoute = (origin, destination) => {
    const originCode = getAirlineCode(origin);
    const destinationCode = getAirlineCode(destination);
    return `https://www.rawfares.com/flights/cheap-flights-from-${origin?.toLowerCase().replace(/\s+/g, '-')}-to-${destination?.toLowerCase().replace(/\s+/g, '-')}-${originCode?.toLowerCase()}-${destinationCode?.toLowerCase()}`;
  };
  
  // Apply the standardization to create an array of standardized airline names from filteredImages
  const airlineNamesFromImages = filteredImages.map(imageName => standardizeAirlineName(imageNameToAirline[imageName]));
  return (
    <>
    <MetaTitle pageTitle={firstResponseData?.metaTitle} pageDescription={firstResponseData?.metaDesc} url={`${apiIP}/deals/${airlineName}`} />
      <Searchbar backgroundImage={`https://imgfolders.com/rawfares/rawfaresUS/deals/deal_cover/${firstResponseData?.url}.webp`} />
      
          <div class="dels-sction"  >
            <div class="best-cntnr">
              <div class="col-first">
                <h1>{firstResponseData?.pageName}</h1>
                <div
                  dangerouslySetInnerHTML={{
                    __html: firstResponseData?.contentData,
                  }}
                ></div>

                {/* <p>{firstResponseData?.dealCode}</p> */}

                {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.    
	  </p> */}
                {/* <div class="cntrbox">
		 <div class="middle-img">
			 <img src="resources/images/airline/img-boxes1.png" alt=""/>
		</div>
		 <div class="middle-img">
			 <img src="resources/images/airline/img-boxes2.png" alt=""/>
		</div>
		 </div> */}

                {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.   
	  </p> */}

                {/* <ul>
		<li>injected humour</li>
		<li>many variations of passages</li>
		<li>dictionary of over 200 Latin</li>	
		
		</ul> */}

                {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,</p> */}
              </div>

              <div class="col-last">
              {filteredImages.map((imageName, index) => (
        <div className="sidebox" key={index}> 
          <Link to={createURLIMG(imageName)} /*onClick={() => handleCardClick("deals", imageNameToAirline[imageName])}*/ target='_blank' style={{cursor: 'pointer'}}>
            <img
              src={`https://imgfolders.com/rawfares/rawfaresUS/deals/deal_side/${imageName}`}
              alt={imageNameToAirline[imageName]}
            />
          </Link>
        </div>
      ))}

                <div class="side_airs">
  <h4>Top Deals</h4>
  <ul>
    {["Business Class", /*"Delta Air Lines", */"Domestic", "Honeymoon", "International Travel", "Last Minute", "LGBTQ", "Military", "Senior", "Student", "Summer"].filter(airlineName => (
      // Check if the airline name is not equal to firstResponseData.pageName and not present in airlineNamesFromImages
      firstResponseData?.pageName !== airlineName && !airlineNamesFromImages.includes(airlineName)
    )).map(airlineName => (
      <li key={airlineName}>
        <Link to={createURL(airlineName)} target='_blank' onClick={() => fetchData()}>
          {airlineName} <img src={Arror} alt="arowimg" />
        </Link>
      </li>
    ))}
  </ul>
</div>
              </div>

              <div class="full-width">
                {/* <div class="tabgcolor">
                  <h3>
                    Flight Deals from {secondResponseData?.response[0]?.froCityName} to{" "}
                    {secondResponseData?.response[0]?.toCityName}
                  </h3>
                  <div
                    class="maintabs"
                    style={{
                      height: "auto",
                      backgroundImage: `url(https://imgfolders.com/rawfares/rawfaresUS/airline_detail/airlinecover_banner/${firstResponseData?.pageValue}.webp)`,
                      backgroundSize: "cover",
                    }}
                  >
                    <div class="tab">
  <div class="tabs_heading">Flight From</div>
  {secondResponseData &&
  secondResponseData.response &&
  Array.isArray(secondResponseData.response) ? (
    secondResponseData.response.map((item, index) => {
      // Remove "intl airport" from the city name
      const cityName = item.froCityName.replace(" Intl Airport", "");

      return (
        <button
          class={`linkstab ${item === selectedFlight ? 'selected' : ''}`}
          key={index}
          onClick={() => handleFroCityClick(item)}
        >
          <img src={DealsGlobe} alt="DealsGlobe" />{" "}
          {cityName}
          <span class="tbarow">
            <img src={tabsArrow} alt="tabsArrow" />
          </span>
        </button>
      );
    })
  ) : (
    <p>No responses available</p>
  )}
  {/* <!--//////////More Button////////////--> 
</div>

                    {/* <!--Tabs--> 
                    {selectedFlight ? (
                      <div id="newyorkcity" class="bottomright">
                        <h3 style={{ color: "#fff" }}>
                          Fly To {selectedFlight.toCityName}
                        </h3>
                        <div class="cityfaremain">
                          <div class="citydate">
                            <p>
                              Depart Date-
                              {
                                formatDateAndTime(selectedFlight.depDate)
                                  .formattedDateString
                              }
                            </p>
                            <p>
                              Return Date-
                              {
                                formatDateAndTime(selectedFlight.retDate)
                                  .formattedDateString
                              }
                            </p>
                          </div>
                          <div class="city-fare">
                            <p>Fare*</p>
                            <p>
                              ${selectedFlight.totalPrice} {/*<sup>00</sup>
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <p>Select a city to view details.</p>
                    )}

                    <div style={{ clear: "both" }}></div>
                  </div>

                  {/* <script>
function openCity(evt, cityName) {
  var i, tabcontent, linkstab;
  tabcontent = document.getElementsByClassName("tabcontent");
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = "none";
  }
  linkstab = document.getElementsByClassName("linkstab");
  for (i = 0; i < linkstab.length; i++) {
    linkstab[i].className = linkstab[i].className.replace(" active", "");
  }
  document.getElementById(cityName).style.display = "block";
  evt.currentTarget.className += " active";
}
// Get the element with id="defaultOpen" and click on it
document.getElementById("defaultOpen").click();
</script> 

                  <div style={{ clear: "both" }}></div>
                  <p class="dismer">
                    <span>Disclamer:-</span> All fares on RawFares.com are
                    quoted in USD. These fare have Originated from historical
                    data. Might vary and cannot be promised at the time of
                    booking.
                  </p>
                </div> */}

                 <h3 style={{ paddingTop: "20px" }}>{firstResponseData?.airportHub}</h3>
                 <div
                  dangerouslySetInnerHTML={{
                    __html: firstResponseData?.travelTrends,
                  }}
                ></div>
               {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, dummy
                  text of the printing and typesetting industry. Lorem Ipsum has
                  been the industry's standard dummy text ever since the 1500s,
                  when an unknown printer took a galley of type and scrambled it
                  to make a type specimen book. It has survived not only five
                  centuries
                </p> */}

                {/* <p>
                  There are many variations of passages of Lorem Ipsum
                  available, but the majority have suffered alteration in some
                  form, by injected humour, or randomised words which don't look
                  even slightly believable. If you are going to use a passage of
                  Lorem Ipsum, you need to be sure there isn't anything
                  embarrassing hidden in the middle of text. All the Lorem Ipsum
                  generators on the Internet tend to repeat predefined chunks as
                  necessary, making this the first true generator on the
                  Internet. It uses a dictionary of over 200 Latin words,
                  combined with a handful of model sentence structures, to
                  generate Lorem Ipsum which looks reasonable. The generated
                  Lorem Ipsum is therefore always free from repetition, injected
                  humour, or non-characteristic words etc. by injected humour,
                  or randomised words which don't look even slightly believable.
                  If you are going to use a passage of Lorem Ipsum, you need to
                  be sure there isn't anything embarrassing hidden in the middle
                  of text. All the Lorem Ipsum generators on the Internet tend
                  to repeat predefined chunks as necessary, making this the
                  first true generator on the Internet. It uses a dictionary of
                  over 200 Latin words, combined with a handful of model
                  sentence structures, to generate Lorem Ipsum which looks
                  reasonable.
                </p> */}

<div className="d-md-flex justify-content-between w-100">
      <div class="side_airs wish-side-sir">
  <h4>Top Flights Domestic</h4>
  <ul>
  {["Dallas", /*"Delta Air Lines" */ "Boston", "Charleston", "Hawaii", "Chicago", "Las Vegas", "Orlando", "Seattle", "San Jose", "Florida"].filter(airlineName => (
      // Check if the airline name is not equal to firstResponseData.pageName and not present in airlineNamesFromImages
      firstResponseData?.pageName !== airlineName && !airlineNamesFromImages.includes(airlineName)
    )).map(airlineName => (
      <li key={airlineName}>
        <Link to={createURLNew(airlineName)} target='_blank' onClick={() => fetchData()}>
          {airlineName} <img src={Arror} alt="arowimg" />
        </Link>
      </li>
    ))}
  
  </ul>
</div>
<div className="side_airs wish-side-sir">
  <h4>Top Flight Routes</h4>
  <ul>
    {[
      { origin: "Boston", destination: "Newport" },
      { origin: "Los Angeles", destination: "Chicago" },
      { origin: "Denver", destination: "Chicago" },
      { origin: "Honolulu", destination: "Kahului" },
      { origin: "Las Vegas", destination: "Los Angeles" },
      { origin: "Louisville", destination: "Chicago" },
      { origin: "Los Angeles", destination: "San Francisco" },
      { origin: "New York", destination: "Washington" },
      { origin: "Seattle", destination: "Boston" },
      { origin: "Westchester", destination: "Chicago" }
      // Add more origin-destination pairs
    ].map(({ origin, destination }) => (
      <li key={`${origin}-${destination}`}>
        <Link
          to={createURLRoute(origin, destination)}
          target="_blank"
          onClick={() => fetchData()}
        >
          From {origin} to {destination} <img src={Arror} alt="arowimg" />
        </Link>
      </li>
    ))}
  </ul>
  {/* <button>View More</button> */}
</div>
<div class="side_airs wish-side-sir">
  <h4>Top International Flights</h4>
  <ul>
  {["Barcelona", /*"Delta Air Lines"*/  "Cancun", "London", "Paris", "Rome", "Tokyo", "Toronto", "Vancouver", "Sydney", "South Island"].filter(airlineName => (
      // Check if the airline name is not equal to firstResponseData.pageName and not present in airlineNamesFromImages
      firstResponseData?.pageName !== airlineName && !airlineNamesFromImages.includes(airlineName)
    )).map(airlineName => (
      <li key={airlineName}>
        <Link to={createURLNew(airlineName)} target='_blank' onClick={() => fetchData()}>
          {airlineName} <img src={Arror} alt="arowimg" />
        </Link>
      </li>
    ))}
  
  </ul>
</div>
      </div>
                
              </div>
            </div>
          </div>
        <div style={{clear: 'both'}}></div>
        <Footer dealType={'Flights'} />
      
    </>
  );
}
