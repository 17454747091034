/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import Logo from '../../resources/images/Rawfare-logo.png';
//import Logo2 from '../../resources/images/logo2.png'
import '../../resources/css/navber.css';
import '../../resources/css/meanmenu.css';
//import '../../resources/js/custom';
//import '../../resources/js/jquery.meanmenu';
import us from '../../resources/images/flag_us.png';
import uk from '../../resources/images/flag_uk.png';
import ca from '../../resources/images/flag_ca.png';
import callIcon from '../../resources/images/call-icon.png'
import {BrowserRouter, Link, NavLink } from 'react-router-dom';


export default function Header({ backgroundColor }) {
    const [utmCampaign, setUtmCampaign] = useState("");
    const [displayNumber, setDisplayNumber] = useState("");
    const jsonMapping = {
        domestics_routes: "+1-833-609-8333",
        domestics_destination: "+1-833-609-7300",
      };
      const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found

      useEffect(() => {
        const updateDisplayNumber = () => {
          const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
        //   console.log(utmData)
          if (utmData?.utmData?.utm_campaign) {
            // console.log("in")
            setUtmCampaign(utmData.utm_campaign);
    
            // Check if `utm_campaign` exists in the JSON mapping
            const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
            setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
          } else {
            // console.log("out")
            // No UTM data found, use the default number
            setDisplayNumber(defaultNumber);
          }
        };
    
        // Run initially
        updateDisplayNumber();
    
        // Set up interval to check every 10 seconds
        const interval = setInterval(updateDisplayNumber, 10000);
    
        // Clean up interval on component unmount
        return () => clearInterval(interval);
      }, [jsonMapping]);
    const headerStyle = {
        backgroundColor: backgroundColor,
        // Add other styles
      };
  return (
    
   
    <header style={headerStyle} className="main_header_arae">
            <div className="navbar-area" style={{backdropFilter: 'blur(10px)'}}>
                <div className="main-responsive-nav">
                    <div className="container">
                        <div className="main-responsive-menu" style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div className="logo">
                                <NavLink to="/">
                                    <img src={Logo} alt="logo"/>
                                </NavLink>
                            </div>
                            {/* <div className="dot-menu">
                            <Link to="tel:+1-888-415-1536">
                                <img src={callIcon} alt="icons" />
                            </Link>
                        </div> */}
                         <div className="call-number others-options">
                                
                                <Link to={`tel:${displayNumber}`} title="Call Us 24/7 for Lowest Airfares"><b>{displayNumber}</b></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-navbar">
                    <div className="container">
                        <nav className="navbar navbar-expand-md navbar-light">
                            <Link className="navbar-brand" to="/">
                                <img src={Logo} alt="logo"/>
                            </Link>
                            <div className="collapse navbar-collapse mean-menu">
                                {/* <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link active">Flight</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="/" className="nav-link">
                                            Deals
                                            <i className="fas fa-angle-down"></i>
                                        </Link>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item">
                                                <Link to="/" className="nav-link">First Class Flight Deals</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/" className="nav-link">Business Class Flight Deals</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/" className="nav-link">Student Travel Deals</Link>
                                            </li>
                                        </ul>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/" className="nav-link">Contact</NavLink>
                                    </li>
                                </ul> */}
                            </div>
                            <div className="call-number others-options">
                                
                                <Link to={`tel:${displayNumber}`} title="Call Us 24/7 for Lowest Airfares"><b>{displayNumber}</b></Link>
                            </div>
                            <div className="country-flag">
                                <div className="sub_header_link">
                                    <img src={us} alt="flag_us"/>
                                     {/*<i className="down_arw"></i> */}
                                    {/* <div className="dropdown-country">
                                        <a href="https://www.rawfares.co.uk/">
                                            <img src={uk} alt="flag_uk"/>
                                            GBP &pound;
                                        </a>
                                        <a href="https://www.rawfares.ca/">
                                            <img src={ca} alt="flag_ca"/>
                                            CAD $
                                        </a>
                                    </div> */}
                                </div>
                            </div>
                        </nav>

                    </div>
                </div>

                {/* <div className="others-option-for-responsive">
                    <div className="container">
                        

                    </div>
                </div> */}

            </div>
        </header>
        
  )
}
