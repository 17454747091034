/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from "react";
import $ from 'jquery';
import 'jquery-validation';
import '../resources/css/Contactus.css'
import Footer from '../components/Footer/footer';
import MetaTitle from './metaTitle';
import SITE_CONFIG from '../SiteController/SiteController';

export default function ContactUs() {
    const { apiIP, siteName, siteID } = SITE_CONFIG;
    const [utmCampaign, setUtmCampaign] = useState("");
    const [displayNumber, setDisplayNumber] = useState("");
    const jsonMapping = {
        domestics_routes: "+1-833-609-8333",
        domestics_destination: "+1-833-609-7300",
      };
      const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found

      useEffect(() => {
        const updateDisplayNumber = () => {
          const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
        //   console.log(utmData)
          if (utmData?.utmData?.utm_campaign) {
            // console.log("in")
            setUtmCampaign(utmData.utm_campaign);
    
            // Check if `utm_campaign` exists in the JSON mapping
            const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
            setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
          } else {
            // console.log("out")
            // No UTM data found, use the default number
            setDisplayNumber(defaultNumber);
          }
        };
    
        // Run initially
        updateDisplayNumber();
    
        // Set up interval to check every 10 seconds
        const interval = setInterval(updateDisplayNumber, 10000);
    
        // Clean up interval on component unmount
        return () => clearInterval(interval);
      }, [jsonMapping]);
    useEffect(() => {
        $('.numbersOnly').keyup(function () {
            if (this.value !== this.value.replace(/[^0-9\\.]/g, '')) {
               this.value = this.value.replace(/[^0-9\\.]/g, '');
            }
        });
$('.textOnly').keyup(function () {
              if (this.value !== this.value.replace(/[^a-zA-Z ]+/g, '')) {
                   this.value = this.value.replace(/[^a-zA-Z ]+/g, '');
                }
        });			
$("#contactForm").validate({
     rules:{
         firstName : {
             required : true
         },
         lastName :{
             required : true
         },
         email :{
             required : true,
             email : true
         },
         phone :{
             required : true
         },
         message :{
             required : true
         }
     },
      errorPlacement: function(error, element) {   },
       submitHandler: function(form) {
                var data = {
                        firstName: $("#firstName").val(),
                        lastName: $("#lastName").val(),
                        email : $("#email").val(),
                        phone : $("#phone").val(),
                        message : $("#message").val()
                            }
                        $.ajax({
                            url: '/contactus/submit',
                            type: 'post',
                            contentType: 'application/json; charset=utf-8',
                            data: JSON.stringify(data),
                            dataType: "text",
                            success: function(response) {
                                alert(response)
                                if(response === 'true')
                                $("#reviewSuccess").text("Contact Query Submit Successfully!").css("color", "green");
                                else
                                $("#reviewSuccess").text("Some Technical Issue Please try later!").css("color", "red");	
                            }
                        });
                        
            
      }
 })
    }, [])
  return (
    <>
    <MetaTitle pageTitle='Contact with Rawfares for Customer Support' pageDescription='You can Contact with Rawfares for Customer Support of booking, baggage, cancellation, and refund issues.' url={`${apiIP}/contact-us`} />
    <body>
    
    {/* <!--Header Ends Here--> */}
    <div class="blog-head1">
        {/* <span>Contact Us </span> */}
    </div>
    <div style={{backgroundColor: '#ffffff', marginTop: '50px'}} >
    <div class="contactus">
        <div class="contact-details">
            <h3>Contact Us </h3>
            <span><i class="fa fa-map-marker"></i> &nbsp; Address: 5830 N Valentine Ave #102, Fresno, CA 93711 USA</span>
            <span><i class="fa fa-envelope"></i> &nbsp;<a href="mailto:support@rawfares.com">support@rawfares.com</a></span>
            <span><i class="fa fa-phone"></i> &nbsp;{displayNumber}</span>
            {/* <!-- <span><i class="fa fa-phone"></i> &nbsp;1-800-404-0025-Consumer Service</span> --> */}
            <hr/>
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3110.9405274523306!2d-75.21420562492851!3d38.76506805450267!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b8b90d99b4ec3f%3A0xe2fe2d10223fc02d!2s6192%20Coastal%20Hwy%2C%20Lewes%2C%20DE%2019958%2C%20USA!5e0!3m2!1sen!2sin!4v1682068723134!5m2!1sen!2sin" width="100%" height="350" style={{border: '0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13233.121944367114!2d-118.1616134!3d33.9853286!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c2ce5eaaaaaaa9%3A0x72c4ed4517982fc!2sRegus%20-%20Commerce%20Corporate%20Center!5e0!3m2!1sen!2sin!4v1701860397123!5m2!1sen!2sin"  style={{border: '0'}} width="100%" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3194.514892317427!2d-119.85637112479425!3d36.80617986736345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8094663cb9bea9ed%3A0x28aab5da9e25b533!2s5830%20N%20Valentine%20Ave%20%23102%2C%20Fresno%2C%20CA%2093711%2C%20USA!5e0!3m2!1sen!2sin!4v1715255796087!5m2!1sen!2sin"  style={{border: '0'}} width="100%" height="350" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div class="contact-form">
            <h3>Get in Touch with Us </h3>
            <form modelAttribute="contactForm" action="/contactus/submit" method="post">
                <div class="flname">
                    <div><label>First Name</label><br/><input type="text" path="firstName" class="textOnly" /></div>
                    <div><label>Last Name</label><br/><input type="text" path="lastName" class="textOnly" /></div>
                </div>
                <div class="phonemail">
                    <div><label>Phone No.</label><br/><input type="text" path="phone" class="numbersOnly" /></div>
                    <div><label>Email</label><br/><input type="text" path="email" /></div>
                </div>
                <div class="comment">
                    <label>Your Comment</label>
                    <textarea path="message"></textarea>
                </div>
                <div class="submit-form">
                    <button type="submit">Submit</button>
                </div>
            </form>
			<div id="reviewSuccess"></div>
        </div>
    </div>
    </div>
    <Footer dealType={'Flights'} />
</body>
</>
  )
}
