/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Loader from '../../resources/loader/loader.gif';
//import '../../resources/font/bootstrap-icons.css';
//import '../../resources/css/meanmenu.css';
import '../../resources/css/footer-style.css';
import Mail from '../../resources/images/icons/mailing_icon_.png';
import fb from '../../resources/images/icons/facebook-icon.svg';
import insta from '../../resources/images/icons/instagram-icon.svg';
import pinterest from '../../resources/images/icons/pinterest.png'
import twit from '../../resources/images/icons/twitter-icon.png';
import SITE_CONFIG from '../../SiteController/SiteController';
//import '../../resources/css/meanmenu.css';
//import '../../resources/jc/owl.carousel.min.css'

export default function Footer({ dealType }) {
    const { apiIP, siteName, siteID } = SITE_CONFIG;
    const [utmCampaign, setUtmCampaign] = useState("");
    const [displayNumber, setDisplayNumber] = useState("");
    const jsonMapping = {
        domestics_routes: "+1-833-609-8333",
        domestics_destination: "+1-833-609-7300",
      };
      const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found

      useEffect(() => {
        const updateDisplayNumber = () => {
          const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
        //   console.log(utmData)
          if (utmData?.utmData?.utm_campaign) {
            // console.log("in")
            setUtmCampaign(utmData?.utm_campaign);
    
            // Check if `utm_campaign` exists in the JSON mapping
            const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
            setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
          } else {
            // console.log("out")
            // No UTM data found, use the default number
            setDisplayNumber(defaultNumber);
          }
        };
    
        // Run initially
        updateDisplayNumber();
    
        // Set up interval to check every 10 seconds
        const interval = setInterval(updateDisplayNumber, 1000000000000);
    
        // Clean up interval on component unmount
        return () => clearInterval(interval);
      }, [jsonMapping]);
   
        // Step 2: Create a state variable for the checkbox status
        const [isChecked, setIsChecked] = useState(false);

        const handleCheckboxChange = (event) => {
          setIsChecked(event.target.checked);
        };
//         const [isLoading, setIsLoading] = useState(false);
//     const [airlines, setAirlines] = useState({
//         pageType: "Airline",
//         pageValue: "",
//         siteId: "2",
//         url: ""
//       });

//       const [deals, setDeals] = useState({
//         froCity:"",
//         toCity:"",
//         airline: airlines.pageValue,
//         cabinClass:"Y",
//         tripType:"2",
//         page:"",
//         currency:"USD",
//         siteId:"2",
//         departDate:"",
//         countryName:"US",
//         countryCode:"US",
//         cityName:""
//     });
//       const [responseData, setResponseData] = useState(null);
//       const navigate = useNavigate();
//       useEffect(() => {
//         // Use a useEffect to make the API call whenever airlines state changes
//         if (airlines.pageValue && airlines.url) {
//           handleAirlineClicked();
//         }
//       }, [airlines]);
    
//       const handleAirlineClicked = async () => {
// //         document.body.style.overflowY = 'hidden';
// //       const homeBody = document.querySelector('.HomeBody');
// // if (homeBody) {
// //   const elementsToBlur = homeBody.querySelectorAll(':not(.loader-overlay)');
// //   elementsToBlur.forEach((element) => {
// //     element.style.filter = 'blur(8px)';
// //   });
// // }
//       //setIsLoading(true)
//         try {
//           const response1 = await axios.post(
//             "https://rawfares.com:9090/api/Airlines-Details",
//             airlines
//           );
      
//           if (response1.status === 200) {
//             setResponseData(response1.data);
//             console.log("Response from the first API:", response1.data);
//             console.log(deals)
//             const response2 = await axios.post("https://rawfares.com:9191/api/Airlines-Routes", deals);
      
//             if (response2.status === 200) {
//               const Data = {
//                 response1: response1.data,
//                 response2: response2.data
//               };
//               console.log("Response from the Second API:", response2.data);
//               const encodedData = encodeURIComponent(JSON.stringify(Data));
//               const navigationPath = airlines.pageValue.length === 2
//                 ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
//                 : `/flights-to/${airlines.url}-${airlines.pageValue}`;
//                 const newTab = window.open(navigationPath + `?data=${encodedData}`, '_blank');
//                 newTab.focus();
//               // navigate(navigationPath, {
//               //   state: Data
//               // });
//             } else {
//               console.error("Request to the second API failed with status:", response2.status);
//             }
//           } else {
//             console.error("Request to the first API failed with status:", response1.status);
//           }
//         } catch (error) {
//           console.error("Error making one of the API requests:", error);
//         }
//       };
      
      
    
//       const handleCardClick = (pageType, pageValue, url) => {
//         // Set the new values for airlines based on the card clicked
//         setAirlines({
//           ...airlines,
//           pageType,
//           pageValue,
//           url,
//         });
//       };

//       const openLink = (url) => {
// 		window.open(url, '_blank', 'width=650,height=550,left=300,top=50');
// 	  };
const [isLoading, setIsLoading] = useState(false);
    const [airlines, setAirlines] = useState({
        pageType: "Airline",
        pageValue: "",
        siteId: siteID,
        url: ""
      });

      const [deals, setDeals] = useState({
        froCity:"",
        toCity:"",
        airline: airlines.pageValue,
        cabinClass:"Y",
        tripType:"2",
        page:"",
        currency:"USD",
        siteId: siteID,
        departDate:"",
        countryName:"US",
        countryCode:"US",
        cityName:""
    });
      const [responseData, setResponseData] = useState(null);
      const navigate = useNavigate();
      useEffect(() => {
        // Use a useEffect to make the API call whenever airlines state changes
        if (airlines.pageValue && airlines.url) {
          handleAirlineClicked();
        }
      }, [airlines]);
    
      const handleAirlineClicked = async () => {
        //     setIsLoading(true);
        //     try {
        //       const response1 = await axios.post("http://localhost:9090/api/Airlines-Details", airlines);
        
        //       if (response1.status === 200) {
        //         setResponseData(response1.data);
        //         // Handle response1 data as needed
        //         const response2 = await axios.post("http://localhost:9191/api/Airlines-Routes", deals);
        
        //         if (response2.status === 200) {
        //           // Handle response2 data as needed
                  
        //           const Data = {
        //             response1: response1.data,
        //             response2: response2.data
        //           };
        //           openNewTabWithData(Data);
        //           setIsLoading(false);
        //         } else {
        //           console.error("Request to the second API failed with status:", response2.status);
        //         }
        //       } else {
        //         console.error("Request to the first API failed with status:", response1.status);
        //       }
        //     } catch (error) {
        //       console.error("Error making one of the API requests:", error);
        //     }
        //   };
        
        //   const openNewTabWithData = (Data) => {
            const navigationPath = airlines.pageValue.length === 2
              ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
              : `/flights-to/${airlines.url}-${airlines.pageValue}`;
        
            const newTab = window.open(navigationPath, '_blank');
            if (newTab) {
                //console.log('Received data in new tab:');
              newTab.onload = function() {
                newTab.postMessage(window.location.origin);
              };
            } else {
              //console.error('Failed to open new tab. Please check your browser settings or extensions.');
            }
        
            // Navigate to the new page with data passed via state
            //navigate(navigationPath);
          };
      
      
    
      const handleCardClick = (pageType, pageValue, url) => {
        // Set the new values for airlines based on the card clicked
        setAirlines({
          ...airlines,
          pageType,
          pageValue,
          url,
        });
      };

      const openLink = (url) => {
		window.open(url, '_blank', 'width=650,height=550,left=300,top=50');
	  };
  return (
    <>
     
        
        {isLoading && (
        <div className="loader-overlay">
          <img src={Loader} alt="Loader" />
        </div>
      )}
      <footer className="footer-area">
            <div className="row">
                <div className="col-12 col-md-12">
                    <div className="container">
                        {/* <!--Subscribe box Start here --> */}
                        <div className="Boxsubscribe" style={{display: 'none'}}>
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="left">
                                        <span className="bell-icon">
                                            <img src={Mail} alt="bell icon" />
                                        </span>
                                        <div>
                                            <h4>Subscribe to our Newsletter</h4>
                                            <p>Get latest offers from Farehutz</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    {/* <!-- Subscribebox--> */}
                                    {/* <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={isChecked} onChange={handleCheckboxChange}></input> */}
                                    <div className="subscribe_div">
                                        <div className="subscribe">
                                            <div className="column2">
                                                <div className="select-dropdown drop-arrow">
                                                    <input type="text" id="subscribeEmail" placeholder="E-mail address" className="inputform" autoComplete="false" />
                                                </div>
                                                <div className="row d-block d-sm-none">
                                                    <div className="col-12 inputSet nofill mb-2">
                                                        {/* <label className="float-start p-0"> <input className="subscribeCheck" type="checkbox" checked={isChecked} onChange={handleCheckboxChange}/> <span className="checkmark"></span> </label> */}
                                                        
                                                        By clicking I have read and agree to the
                                                    </div>
                                                </div>
                                                <button type="button" disabled={!isChecked} className="btn btn-primary" style={{height:'45px', borderRadius: '4px 4px 4px 4px', width: '150px'}}>subscribe</button>
                                            </div>
                                            
                                            <div id="subscribeError"></div>
                                            <div className="clearfix"></div>
                                            <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={isChecked} onChange={handleCheckboxChange} style={{margin: '3px'}}></input>
                                                By clicking, I have read and agree to the
                                            <div className="row d-none d-sm-block">
                                                <div className="col-12 inputSet nofill mb-2">
                                                    {/* <label className="float-start p-0"> <input className="subscribeCheck" checked={isChecked} onChange={handleCheckboxChange} type="checkbox"/> <span className="checkmark"></span> </label> */}
                                                    
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <div className="sucessfullMsg" style={{display: "none"}}>
                                            <div className="thanks"><img src="index.html" alt="" className="pull-left" /> Thanks</div>
                                            <div className="msg">
                                                Your email ID <br />
                                                <span id="email2">iliyas.ahmad@Travels.com</span> <br />
                                                <span id="submitSuccess2"> has been added successfully</span>
                                            </div>
                                            <small className="text">We will notify you for our Best Deals and offers</small>
                                        </div>
                                    </div>

                                    {/* <!-- Subscribebox--> */}
                                </div>
                            </div>
                        </div>
                        {/* <!--Subscribe box End here --> */}
                    </div>
                    {/* <!--footer box Start here --> */}
                    <div className="top-footer">
                        <div className="container">
                            <div className="holder">
                                
                                <div className="quickLinks">
                                    <div className="row">
                                        <div className="col-md-3 col-6">
                                        <h5>Traveler Assistance</h5>
                                            <ul>
                                                <li><Link to={`${apiIP}/about`}>About Us</Link></li>
                                                <li><Link to={`${apiIP}/online-check-in`} >Online Check-in</Link></li>
                                                {/* <li>Check Flight Status</li> */}
                                                <li><Link to={`${apiIP}/airline-baggage-fee`} onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Airline Baggage Fees</Link></li>
                                                <li><Link to='/taxes-and-fees' onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Taxes & Fees</Link></li>
                                                <li><Link to='/post-ticketing-fee'>Post Ticketing Fees</Link></li>
                                                <li><Link to='/our-services-fee' onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href);}}>Our Service Fees</Link></li>
                                                {/* <li>Newsletter</li>
                                                <li>Client Testimonial</li>
                                                <li>Blogs</li> */}
                                                <li><Link to='/contact-us'>Contact Us</Link></li>
                                            </ul>
                                            
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <h5>Popular Airlines</h5>
                                            <ul>
                                                <li><a href={`${apiIP}/airline/american-airlines-aa-flights`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("Airline", "aa", "american-airlines")}}>American Airlines</a></li>
                                                <li><a href={`${apiIP}/airline/united-airlines-ua-flights`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("Airline", "ua", "united-airlines")}}>United Airlines</a></li>
                                                <li><a href={`${apiIP}/airline/spirit-airlines-nk-flights`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("Airline", "nk", "spirit-airlines")}}>Spirit Airlines</a></li>
                                                <li><a href={`${apiIP}/airline/lufthansa-airlines-lh-flights`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("Airline", "lh", "lufthansa-airlines")}}>Lufthansa Airlines</a></li>
                                                <li><a href={`${apiIP}/airline/hawaiian-airlines-ha-flights`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("Airline", "ha", "hawaiian-airlines")}}>Hawaiian Airlines</a></li>
                                                <li><a href={`${apiIP}/airline/frontier-airlines-f9-flights`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("Airline", "f9", "frontier-airlines")}}>Frontier Airlines</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-md-3 col-6 d-md-none d-block">
                                        <h5>Top Flights Domestic</h5>
                                            <ul>
                                                <li><a href={`${apiIP}/flights/cheap-flights-to-dallas-dfw`} target="_blank">Dallas</a></li>
                                                <li><a href={`${apiIP}/flights/cheap-flights-to-boston-bos`}  target="_blank" rel="noopener noreferrer">Boston</a></li>
                                                {/* <li>Check Flight Status</li> */}
                                                <li><a href={`${apiIP}/flights/cheap-flights-to-denver-den`} target="_blank" rel="noopener noreferrer">Denver</a></li>
                                                <li><a href={`${apiIP}/flights/cheap-flights-to-charleston-chs`} target="_blank" rel="noopener noreferrer">Charleston</a></li>
                                                <li><a href={`${apiIP}/flights/cheap-flights-to-hawaii-hi`} target="_blank" rel="noopener noreferrer">Hawaii</a></li>
                                                <li><a href={`${apiIP}/flights/cheap-flights-to-chicago-ord`} target="_blank" rel="noopener noreferrer">Chicago</a></li>
                                                <li><a href={`${apiIP}/flights/cheap-flights-to-las-vegas-las`} target="_blank" rel="noopener noreferrer">Las Vegas</a></li>
                                            </ul>
                                            
                                        </div>
                                        <div className="col-md-3 col-6 d-md-none d-block">
                                        <h5>Top International Flights</h5>
                                            <ul>
                                                <li><a href={`${apiIP}/flights/cheap-flights-to-barcelona-bcn`} target="_blank" rel="noopener noreferrer">Barcelona</a></li>
                                                <li><a href={`${apiIP}/flights/cheap-flights-to-cancun-cun`} target="_blank" rel="noopener noreferrer">Cancun</a></li>
                                                {/* <li>Check Flight Status</li> */}
                                                <li><a href={`${apiIP}/flights/cheap-flights-to-london-lhr`} target="_blank" rel="noopener noreferrer">London</a></li>
                                                <li><a href={`${apiIP}/flights/cheap-flights-to-paris-par`} target="_blank" rel="noopener noreferrer">Paris</a></li>
                                                <li><a href={`${apiIP}/flights/cheap-flights-to-rome-rom`} target="_blank" rel="noopener noreferrer">Rome</a></li>
                                                <li><a href={`${apiIP}/flights/cheap-flights-to-tokyo-hnd`} target="_blank" rel="noopener noreferrer">Tokyo</a></li>
                                            </ul>
                                            
                                        </div>
                                        <div className="col-md-3 col-6 d-none d-sm-block">
                                        <h5>Popular Destinations</h5>
                                            <ul>
                                                <li><a href={`${apiIP}/flights-to/new-york-nyc`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("City", "nyc", "new-york")}}>New York</a></li>
                                                <li><a href={`${apiIP}/flights-to/miami-mia`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("City", "mia", "miami")}}>Miami</a></li>
                                                <li><a href={`${apiIP}/flights-to/los-angeles-lax`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("City", "lax", "los-angeles")}}>Los Angeles</a></li>
                                                <li><a href={`${apiIP}/flights-to/atlanta-atl`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("City", "atl", "atlanta")}}>Atlanta</a></li>
                                                <li><a href={`${apiIP}/flights-to/mexico-city-mex`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("City", "mex", "mexico-city")}}>Mexico City</a></li>
                                                <li><a href={`${apiIP}/flights-to/amsterdam-ams`} target="_blank" rel="noopener noreferrer" onClick={(e) => {e.preventDefault(); handleCardClick("City", "ams", "amsterdam")}}>Amsterdam</a></li>

                                            </ul>
                                        </div>
                                        <div className="col-md-3 col-12">
                                            <h5>Legal</h5>
                                            <div className="link footer-contact-links">
                                                {/* <a href="aboutus.html"> Terms & Conditions </a> &nbsp; | &nbsp; <a href="contactus.html"> Contact Us </a> <br/> */}
                                                 <Link to='/privacy-policy'>Privacy Policy</Link>  &nbsp; | &nbsp;  <Link to='/terms-and-condition' style={{color: 'white'}}>Terms & Conditions</Link>  <Link to='/sitemap'>Sitemap</Link>  <Link to='/disclaimer' style={{color: 'white'}}>Disclaimer</Link>
                                            </div>
                                            <div className="footer-contact-links">
                                                <h5>US Address:</h5>
                                                5830 N Valentine Ave #102, Fresno, CA 93711 USA
                                            </div>
                                            <div className="social-media d-sm-block">
                                                <h5>Follow Us :</h5>
                                                <div className="clearfix"></div>
                                                <a href="https://www.facebook.com/rawfares" target="_blank" rel="noreferrer" title="Facebook" className="icoFacebook">
                                                    <img src={fb} alt="facebook" />
                                                </a>
                                                <a href="https://www.instagram.com/rawfares_us/" target="_blank" rel="noreferrer" title="Instagram" className="icoFacebook">
                                                    <img src={insta} alt="instagram" />
                                                </a>
                                                <a href="https://www.pinterest.com/rawfares/" target="_blank" rel="noreferrer" title="Pinterest" className="icoFacebook">
                                                    <img src={pinterest} alt="pinterest" />
                                                </a>
                                                {/* <a href="javascrip:;" style={{cursor: 'pointer'}} title="Instagram"  className="icoinstagram">
                                                    <img src={insta} alt="instagram" />
                                                </a> */}
                                                <a href="https://twitter.com/rawfares" target="_blank" title="Twitter" rel="noreferrer" className="icoTwitter">
                                                    <img src={twit} alt="twitter" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <center><img src='https://imgfolders.com/common/Logos.png' alt=''></img></center>
                    <div className="copyright-block">
                        <div className="container">
                            <p style={{textAlign: "center ", color: 'white', fontSize: '13px'}}>Rawfares is represented by Holiay Breakz Group LLC which is registered in 5830 N Valentine Ave #102, Fresno, CA 93711 USA<br/>Rawfares.com is an independent travel portal with no third party association. By using Rawfares.com, you agree that Rawfares is not accountable for any loss - direct or indirect, arising of offers, materials or links to other sites found on this website. In case of queries, reach us directly at our Contact Number +1-888-415-1536 or, simply email at support@rawfares.com</p>
                            <div className="copyright"> Copyrights &copy; 2022-2024 rawfares.com, All Rights Reserved. </div>
                        </div>
                    </div>
                    <div class="visible-xs">
      <div class="bottom-call-bar2" style={{bottom: '0px', fontFamily: 'Arial, Helvetica, sans-serif'}}>    
      <a id="a_contactNo" href="tel:+1-888-415-1536">
        <div class="call-text">Call &amp; Get Unpublished {dealType} Deals!</div>
        <div class="PH_contactNo2">{displayNumber}</div>
        </a> </div>
    </div>
                </div>
            </div>
        </footer>
        {/* <!--container-footer--> */}
        </>
  )
}
