
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import SITE_CONFIG from '../../SiteController/SiteController';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import "../../resources/css/cities-style.css";
import tabsArrow from "../../resources/images/tabsarow.png";
import Arror from '../../resources/images/icons/arowimg.png'
import Searchbar from "../../Shared/Searchbar";
import Footer from "../../components/Footer/footer";
import DealsGlobe from '../../resources/images/globepine.png'
import MetaTitle from "../../pages/metaTitle";

function formatDateAndTime(isoDate) {
  const dateOptions = {
    weekday: "short",
    month: "short",
    day: "numeric",
  };

  const timeOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const formattedDate = new Date(isoDate);
  const formattedDateString = new Intl.DateTimeFormat(
    "en-US",
    dateOptions
  ).format(formattedDate);
  const formattedTimeString = new Intl.DateTimeFormat(
    "en-US",
    timeOptions
  ).format(formattedDate);

  return { formattedDateString, formattedTimeString };
}

export default function Cities(event) {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  const location = useLocation();
  const { response1, response2 } = location.state || {};
   const { airlineName } = useParams();
   const [utmParams, setUtmParams] = useState({});
  console.log("Airline name", airlineName)
  const cleanedAirlineName = airlineName.replace(/^cheap-flights-to-/, '');
  const parts = cleanedAirlineName.split('-');
const cityCode = parts.pop(); // Get "chi"
const cityName = parts.join('-'); // Get "chicago"
const pageValue = cityCode; // Set pageValue to "chi"
const url = `cheap-flights-to-${cityName}-${cityCode}`; // Set url to "chicago-chi"
  const [firstResponseData, setFirstResponseData] = useState(response1?.response[0] || null);
  const [secondResponseData, setSecondResponseData] = useState(response2 || null);
  
   const [reloadData, setReloadData] = useState({
     pageType: "City_PPC",
     pageValue: pageValue,
     siteId: siteID,
     url: url
    // pageType: "City_PPC",
    // pageValue: "chi",
    // siteId: "16",
    // url: "chicago"
   })

   useEffect(() => {
    const storeUtmData = () => {
      const queryParams = new URLSearchParams(window.location.search);
      console.log("queryParams", queryParams)
      const utmData = {};
  
      // Iterate through query parameters and extract UTM parameters
      for (let [key, value] of queryParams.entries()) {
        if (key.startsWith("utm_")) {
          utmData[key] = value;
        }
      }
  
      if (Object.keys(utmData).length > 0) {
        const expirationTime = new Date().getTime() + 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
        const dataWithExpiration = {
          utmData,
          expiresAt: expirationTime,
        };
  
        // Store UTM data in localStorage
        localStorage.setItem("utm_data", JSON.stringify(dataWithExpiration));
        setUtmParams(utmData); // Update state
      }
    };
  
    // Execute on query parameter change
    storeUtmData();
  }, [location.search]);

   const fetchData = async () => {
    
    ////console.log(reloadData)
      try {
        let response;
        if (!response1) {
          console.log("ReloadData", reloadData)
        response = await axios.post(`${apiIP}:9090/api/Airlines-Details`, reloadData);
        console.log("RESS", response)
        // Set firstResponseData state with the response if response1 is null
        if (response.status === 200) {
          setFirstResponseData(response.data.response[0]);
          ////console.log("Response from the first API:", response.data);
          ////console.log(deals)
          const response2 = await axios.post(`${apiIP}:9191/api/Airlines-Routes`,
          { froCity:"",
          toCity:"",
          airline: "",
          cabinClass:"Y",
          tripType:"2",
          page:"",
          currency:"USD",
          siteId: siteID,
          departDate:"",
          countryName:"US",
          countryCode:"US",
          cityName:"" }
          );
          setSecondResponseData(response2.data);
          ////console.log("responswe23",response2);
        }
          
          
        } else {
        // Use the existing response1 if available
        setFirstResponseData(response1.response[0]);
        }
        ////console.log(response);
        // Update state with response data if needed
      } catch (error) {
        ////console.error('Error fetching data:', error);
        // Handle errors appropriately
      }
      };
    
      useEffect(() => {
      // Check if response1 or response2 is null, then call fetchData
      if (!response1 || !response2) {
        fetchData();
      }
      }, [url, pageValue, response1, response2]);
  
    const [selectedFlight, setSelectedFlight] = useState();
    useEffect(() => {
      if (secondResponseData && secondResponseData.response.length > 0) {
        setSelectedFlight(secondResponseData.response[0]);
      }
    }, [secondResponseData]);
  
    const handleFroCityClick = (item) => {
      setSelectedFlight(item);
    };
  
    const imageNames = ['albuquerque.webp', 'amsterdam.webp','atlanta.webp', 'chicago.webp', 'las-vegas.webp', 'hawaii.webp', 'charleston.webp', 'boston.webp', 'cancun.webp', 'barcelona.webp', 'orlando.webp', 'seattle.webp', 'san-jose.webp', 'dallas.webp', 'denver.webp', 'london.webp', 'paris.webp', 'rome.webp', 'tokyo.webp', 'florida.webp'];
      const filteredImages = imageNames
      .filter(name => name !== `${url}.webp`)
      .slice(0, 3); // Limiting to 3 images
  
      const imageNameToAirline = {
        'albuquerque.webp': 'albuquerque',
        'amsterdam.webp': 'amsterdam',
        'atlanta.webp': 'atlanta',
        'chicago.webp': 'chicago',
        'las-vegas.webp': 'las-vegas',
        'hawaii.webp': 'hawaii',
        'charleston.webp': 'charleston',
        'boston.webp': 'boston',
        'cancun.webp': 'cancun',
        'barcelona.webp': 'barcelona',
        'orlando.webp' : 'orlando',
        'seattle.webp' : 'seattle',
        'san-jose.webp' : 'san-jose',
        'dallas.webp' : 'dallas',
        'denver.webp' : 'denver',
        'london.webp' : 'london',
        'paris.webp' : 'paris',
        'rome.webp' : 'rome',
        'tokyo.webp' : 'tokyo',
        'florida.webp': 'florida'
      };
      const imageNameToAirlineCode = {
        'albuquerque.webp': 'abq',
        'amsterdam.webp': 'ams',
        'atlanta.webp': 'atl',
        'chicago.webp': 'ord',
        'las-vegas.webp': 'las',
        'hawaii.webp': 'hi',
        'charleston.webp': 'chs',
        'boston.webp': 'bos',
        'cancun.webp': 'cun',
        'barcelona.webp': 'bcn',
        'orlando' : 'mco',
        'seattle' : 'sea',
        'san-jose' : 'sjc',
        'florida' : 'fl'
      };
  
      const handleSideClick = (pageValue, url) => {
        setReloadData({
          ...reloadData,
          pageValue,
          url,
        });
      };
  
      const getAirlineCode = (name) => {
        const airlineCodes = {
          "Albuquerque": "ABQ",
          "Barcelona": "BCN",
          "Boston": "BOS",
          "Cancun": "CUN",
          "Charleston": "CHS",
          "Hawaii": "HI",
          "Chicago": "ORD",
          "Las Vegas": "LAS",
          "Orlando": "MCO",
          "Seattle": "SEA",
          "San Jose": "SJC",
          "San Francisco": "SFO",
          "Dallas": "DFW",
          "Denver": "DEN",
          "Honolulu": "HNL",
          "Kahului": "OGG",
          "Los Angeles": "LAX",
          "Guadalajara": "GDL",
          "London": "LON",
          "Cuncun": "CUN",
          "New York": "NYC",
          "Paris": "PAR",
          "Toronto": "YYZ",
          "Rome" : "ROM",
          "Tokyo" : "HND",
          "Florida" : "FL",
          "Vancouver" : "YVR",
          "Sydney" : "SYD",
          "South Island": "CHC",
          "Newport" : "ONP",
          "Louisville": "SDF",
          "Washington" : "WAS",
          "Westchester": "HPN"
        };
      
        return airlineCodes[name];
      };
      
      // Function to create a URL with the airline code appended to the airline name
      const createURL = (name) => {
        const airlineCode = getAirlineCode(name);
        return `https://www.rawfares.com/flights/cheap-flights-to-${name?.toLowerCase().replace(/\s+/g, '-')}-${airlineCode?.toLowerCase()}`;
      };

      const createURLRoute = (origin, destination) => {
        const originCode = getAirlineCode(origin);
        const destinationCode = getAirlineCode(destination);
        return `https://www.rawfares.com/flights/cheap-flights-from-${origin?.toLowerCase().replace(/\s+/g, '-')}-to-${destination?.toLowerCase().replace(/\s+/g, '-')}-${originCode?.toLowerCase()}-${destinationCode?.toLowerCase()}`;
      };
  
    const [airlines, setAirlines] = useState({
          pageType: "Airline",
          pageValue: "",
          siteId: siteID,
          url: ""
        });
  
        const [deals, setDeals] = useState({
          froCity:"",
          toCity:"",
          airline: airlines.pageValue,
          cabinClass:"Y",
          tripType:"2",
          page:"",
          currency:"USD",
          siteId: siteID,
          departDate:"",
          countryName:"US",
          countryCode:"US",
          cityName:""
      });
        //const [responseData, setResponseData] = useState(null);
        //const navigate = useNavigate();
        // useEffect(() => {
        //   // Use a useEffect to make the API call whenever airlines state changes
        //   if (airlines.pageValue && airlines.url) {
        //     handleAirlineClicked();
        //   }
        // }, [airlines]);
  
  // const handleAirlineClicked = async () => {
  //     const navigationPath = airlines.pageValue.length === 2
  //       ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
  //       : `/flights-to/${airlines.url}-${airlines.pageValue}`;
  
  //     const newTab = window.open(navigationPath, '_blank');
  //     if (newTab) {
  //         ////console.log('Received data in new tab:');
  //       newTab.onload = function() {
  //         newTab.postMessage(window.location.origin);
  //       };
  //     } else {
  //       ////console.error('Failed to open new tab. Please check your browser settings or extensions.');
  //     }
  
  //     // Navigate to the new page with data passed via state
  //     //navigate(navigationPath);
  //   };
        
        
      
        const handleCardClick = (pageType, pageValue, url) => {
          // Set the new values for airlines based on the card clicked
          setAirlines({
            ...airlines,
            pageType,
            pageValue,
            url,
          });
        };
  
        const standardizeAirlineName = (name) => {
          // Define a mapping of variations to a standard name
          const nameVariations = {
            'albuquerque': 'Albuquerque',
            'amsterdam': 'Amsterdam',
            'atlanta': 'Atlanta',
            'chicago': 'Chicago',
            'las-vegas': 'Las Vegas',
            'hawaii': 'Hawaii',
            'charleston': 'Charleston',
            'boston': 'Boston',
            'cancun': 'Cancun',
            'barcelona': 'Barcelona',
            'orlando': 'Orlando',
            'seattle': 'Seattle',
            'san-jose': 'San Jose',
            'dallas' : 'Dallas',
            'denver' : 'Denver',
            'london' : 'London',
            'paris' : 'Paris',
            'rome' : 'Rome',
            'tokyo' : 'Tokyo',
            'florida' : 'Florida'
            // Add more variations as needed
          };
        
          return nameVariations[name] || name; // If a variation is found, return the standardized name, otherwise return the original name
        };
        
        // Apply the standardization to create an array of standardized airline names from filteredImages
        const airlineNamesFromImages = filteredImages.map(imageName => standardizeAirlineName(imageNameToAirline[imageName]));
  
  
        const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup the event listener on unmount
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  console.log(firstResponseData?.pageName)
  return (
    <>
    <MetaTitle pageTitle={firstResponseData?.metaTitle} pageDescription={firstResponseData?.metaDesc} url={`${apiIP}/airline/${airlineName}`} />
    {firstResponseData && firstResponseData.pageName && (
      <Searchbar backgroundImage={`https://imgfolders.com/rawfares/rawfaresUS/ppc/21rawfares.webp`} isSpecialPage={true} headText={`Cheap Flights to ${firstResponseData?.pageName}`} />
    )}
       {firstResponseData && firstResponseData.pageName && (
          <div class="dels-sction"  >
            <div class="best-cntnr">
              <div class="col-first chang-widh">
              <h2>Flights to {firstResponseData?.pageName}({firstResponseData?.pageValue && firstResponseData.pageValue.toUpperCase()})</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: firstResponseData?.contentData,
                  }}
                ></div>
              </div>
              <div className="d-md-flex justify-content-between w-100">
      <div class="side_airs wish-side-sir">
  <h4>Top Flights Domestic</h4>
  <ul>
  {["Dallas", /*"Delta Air Lines" */ "Boston", "Charleston", "Hawaii", "Chicago", "Las Vegas", "Orlando", "Seattle", "San Jose", "Florida"].filter(airlineName => (
      // Check if the airline name is not equal to firstResponseData.pageName and not present in airlineNamesFromImages
      firstResponseData?.pageName !== airlineName && !airlineNamesFromImages.includes(airlineName)
    )).map(airlineName => (
      <li key={airlineName}>
        <Link to={createURL(airlineName)} target='_blank' onClick={() => fetchData()}>
          {airlineName} <img src={Arror} alt="arowimg" />
        </Link>
      </li>
    ))}
  
  </ul>
  {/* <button>View More</button> */}
</div>
<div className="side_airs wish-side-sir">
  <h4>Top Flight Routes</h4>
  <ul>
    {[
      { origin: "Boston", destination: "Newport" },
      { origin: "Los Angeles", destination: "Chicago" },
      { origin: "Denver", destination: "Chicago" },
      { origin: "Honolulu", destination: "Kahului" },
      { origin: "Las Vegas", destination: "Los Angeles" },
      { origin: "Louisville", destination: "Chicago" },
      { origin: "Los Angeles", destination: "San Francisco" },
      { origin: "New York", destination: "Washington" },
      { origin: "Seattle", destination: "Boston" },
      { origin: "Westchester", destination: "Chicago" }
      // Add more origin-destination pairs
    ].map(({ origin, destination }) => (
      <li key={`${origin}-${destination}`}>
        <Link
          to={createURLRoute(origin, destination)}
          target="_blank"
          onClick={() => fetchData()}
        >
          From {origin} to {destination} <img src={Arror} alt="arowimg" />
        </Link>
      </li>
    ))}
  </ul>
  {/* <button>View More</button> */}
</div>
<div class="side_airs wish-side-sir">
  <h4>Top International Flights</h4>
  <ul>
  {["Barcelona", /*"Delta Air Lines"*/  "Cancun", "London", "Paris", "Rome", "Tokyo", "Toronto", "Vancouver", "Sydney", "South Island"].filter(airlineName => (
      // Check if the airline name is not equal to firstResponseData.pageName and not present in airlineNamesFromImages
      firstResponseData?.pageName !== airlineName && !airlineNamesFromImages.includes(airlineName)
    )).map(airlineName => (
      <li key={airlineName}>
        <Link to={createURL(airlineName)} target='_blank' onClick={() => fetchData()}>
          {airlineName} <img src={Arror} alt="arowimg" />
        </Link>
      </li>
    ))}
  
  </ul>
</div>
      </div>
            </div>
         
          </div>
       )}
        <div style={{clear: 'both'}}></div>
        <Footer dealType={'Flights'} />
      
    </>
  );
}
