/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { Helmet } from 'react-helmet';
import '../resources/css/BaggageFee.css'
import Footer from '../components/Footer/footer';
import MetaTitle from './metaTitle';
import SITE_CONFIG from '../SiteController/SiteController';

export default function BaggageFee() {
  const { apiIP } = SITE_CONFIG;
  return (
    <>
    {/* <Helmet>
        <title>Baggage Fees</title>
        <meta name="description" content="This is the page of Baggage fee" />
        {/* Other meta tags 
        <link rel="canonical" href={`${apiIP}/airline-baggage-fee`} />
        {/* Additional meta tags for homepage 
      </Helmet> */}
    <MetaTitle url={`${apiIP}/airline-baggage-fee`} pageTitle="Find Airline Baggage Fee Information on Rawfares" pageDescription="You can Find All Airline Baggage Fees and policy Information on Rawfares." />
    <body >
    
<div class="page-head" >
Baggage Fees
</div>

<table>
<thead>
<tr>
<th>Airlines</th>
<th>CarryOn</th>
<th>1st Bag</th>
<th>2nd Bag</th>
<th>Additional Policy</th>
</tr>
</thead>
<tbody>
<tr>
                      <td><img src="https://imgfolders.com/farehutz/FL.png" alt="fl" height="25%"/> <a href="https://www.southwest.com/html/customer-service/baggage/index.html?clk=GFOOTER-CUSTOMER-BAGS" rel="nofollow" target="_blank">AirTran &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.southwest.com/html/customer-service/baggage/index.html?clk=GFOOTER-CUSTOMER-BAGS" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.southwest.com/html/customer-service/baggage/index.html?clk=GFOOTER-CUSTOMER-BAGS" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.southwest.com/html/customer-service/baggage/index.html?clk=GFOOTER-CUSTOMER-BAGS" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.southwest.com/html/customer-service/baggage/index.html?clk=GFOOTER-CUSTOMER-BAGS" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/AS.png" alt="as" height="25%"/> <a href="https://www.alaskaair.com/content/travel-info/baggage/checked-bags.aspx" rel="nofollow" target="_blank">Alaska Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.alaskaair.com/content/travel-info/baggage/checked-bags.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.alaskaair.com/content/travel-info/baggage/checked-bags.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.alaskaair.com/content/travel-info/baggage/carry-on-luggage.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.alaskaair.com/content/travel-info/baggage/overview.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/AA.png" alt="aa" height="25%"/> <a href="https://www.aa.com/i18n/customer-service/support/optional-service-fees.jsp#baggage" rel="nofollow" target="_blank">American Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.aa.com/i18n/customer-service/support/optional-service-fees.jsp#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.aa.com/i18n/customer-service/support/optional-service-fees.jsp#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.aa.com/i18n/travel-info/baggage/carry-on-baggage.jsp" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.aa.com/i18n/travel-info/baggage/baggage.jsp" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/UA.png" alt="ua" height="25%"/> <a href="https://www.united.com/web/en-US/content/travel/baggage/default.aspx" rel="nofollow" target="_blank">United Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.united.com/web/en-US/content/travel/baggage/default.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.united.com/web/en-US/content/travel/baggage/default.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.united.com/CMS/en-US/travel/Pages/BaggageCarry-On.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.united.com/web/en-US/content/travel/baggage/default.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/DL.png" alt="dl" height="25%"/> <a href="https://www.delta.com/content/www/en_US/traveling-with-us/baggage.html" rel="nofollow" target="_blank">Delta Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.delta.com/content/www/en_US/traveling-with-us/baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.delta.com/content/www/en_US/traveling-with-us/baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.delta.com/content/www/en_US/traveling-with-us/baggage/during-your-trip/carry-on.html" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.delta.com/content/www/en_US/traveling-with-us/baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/F9.png" alt="f9" height="25%"/> <a href="https://www.flyfrontier.com/travel-information/baggage/" rel="nofollow" target="_blank">Frontier &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.flyfrontier.com/travel-information/baggage/" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.flyfrontier.com/travel-information/baggage/" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.flyfrontier.com/travel-information/baggage/#info" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.flyfrontier.com/travel-information/baggage/" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/HA.png" alt="ha" height="25%"/> <a href="https://www.hawaiianairlines.com/legal/list-of-all-fees" rel="nofollow" target="_blank">Hawaiian Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.hawaiianairlines.com/legal/list-of-all-fees" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.hawaiianairlines.com/legal/list-of-all-fees" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.hawaiianairlines.com/bagfees#/Carryon" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.hawaiianairlines.com/legal/list-of-all-fees" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/B6.png" alt="b6" height="25%"/> <a href="https://www.jetblue.com/travel/baggage/" rel="nofollow" target="_blank">JetBlue Airways &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.jetblue.com/travel/baggage/" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.jetblue.com/travel/baggage/" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.jetblue.com/travel/baggage/#/carry-on-baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.jetblue.com/travel/baggage/" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/YX.png" alt="yx" height="25%"/> <a href="http://www.rjet.com/en/Flying_with_us/Baggage.aspx" rel="nofollow" target="_blank">Republic Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="http://www.rjet.com/en/Flying_with_us/Baggage.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.rjet.com/en/Flying_with_us/Baggage.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.rjet.com/en/Flying_with_us/Baggage.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.rjet.com/en/Flying_with_us/Baggage.aspx" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/SY.png" alt="sy" height="25%"/> <a href="https://www.suncountry.com/Fly/Baggage" rel="nofollow" target="_blank">Sun Country &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.suncountry.com/Fly/Baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.suncountry.com/Fly/Baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.suncountry.com/Fly/Baggage/Carry-On-Baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.suncountry.com/Fly/Baggage/Checked-Baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/NK.png" alt="nk" height="25%"/> <a href="https://www.spirit.com/OptionalServices" rel="nofollow" target="_blank">Spirit Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.spirit.com/OptionalServices" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.spirit.com/OptionalServices" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.spirit.com/OptionalServices" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.spirit.com/OptionalServices" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/ws.png" alt="ws" height="25%"/> <a href="https://www.westjet.com/en-ca/travel-info/baggage/index" rel="nofollow" target="_blank">Westjet &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.westjet.com/en-ca/travel-info/baggage/index" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.westjet.com/en-ca/travel-info/baggage/index" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.westjet.com/en-ca/travel-info/baggage/carry-on" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.westjet.com/en-ca/travel-info/baggage/carry-on" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/US.png" alt="us" height="25%"/> <a href="https://www.americanairlines.in/i18n/travel-info/baggage/baggage.jsp" rel="nofollow" target="_blank">US airways &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.americanairlines.in/i18n/travel-info/baggage/baggage.jsp" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.americanairlines.in/i18n/travel-info/baggage/baggage.jsp" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.aa.com/i18n/travel-info/baggage/carry-on-baggage.jsp" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.americanairlines.in/i18n/travel-info/baggage/baggage.jsp" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/G4.png" alt="g4" height="25%"/> <a href="https://www.allegiantair.com/popup/optional-services-fees#baggage" rel="nofollow" target="_blank">Allegiant Air &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.allegiantair.com/popup/optional-services-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.allegiantair.com/popup/optional-services-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.allegiantair.com/popup/optional-services-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.allegiantair.com/popup/optional-services-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/VX.png" alt="vx" height="25%"/> <a href="https://www.virginamerica.com/cms/vx-fees#baggage" rel="nofollow" target="_blank">Virgin America &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.virginamerica.com/cms/vx-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.virginamerica.com/cms/vx-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.virginamerica.com/cms/vx-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.virginamerica.com/cms/vx-fees#baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/4B.png" alt="4b" height="25%"/> <a href="https://www.boutiqueair.com/p/baggage" rel="nofollow" target="_blank">Boutique Air &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.boutiqueair.com/p/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.boutiqueair.com/p/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.boutiqueair.com/p/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.boutiqueair.com/p/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/9K.png" alt="9k" height="25%"/> <a href="https://www.capeair.com/flying_with_us/baggage.html" rel="nofollow" target="_blank">Cape Air &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.capeair.com/flying_with_us/baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.capeair.com/flying_with_us/baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.capeair.com/flying_with_us/baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.capeair.com/flying_with_us/baggage.html" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/M5.png" alt="m5" height="25%"/> <a href="http://www.kenmoreair.com/Before-you-fly/faq-s/" rel="nofollow" target="_blank">Kenmore Air &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="http://www.kenmoreair.com/Before-you-fly/faq-s/" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.kenmoreair.com/Before-you-fly/faq-s/" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.kenmoreair.com/Before-you-fly/faq-s/" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.kenmoreair.com/Before-you-fly/faq-s/" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/MW.png" alt="mw" height="25%"/> <a href="http://www.mokuleleairlines.com/travel-info/policies/#tab2" rel="nofollow" target="_blank">Mokulele Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="http://www.mokuleleairlines.com/travel-info/policies/#tab2" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.mokuleleairlines.com/travel-info/policies/#tab2" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.mokuleleairlines.com/travel-info/policies/#tab2" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.mokuleleairlines.com/travel-info/policies/#tab2" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/7N.png" alt="7n" height="25%"/> <a href="https://pawadominicana.com/en/passenger-information/luggage/luggage-policy" rel="nofollow" target="_blank">PAWA Dominicana &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://pawadominicana.com/en/passenger-information/luggage/luggage-policy" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://pawadominicana.com/en/passenger-information/luggage/luggage-policy" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://pawadominicana.com/en/passenger-information/luggage/luggage-policy" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://pawadominicana.com/en/passenger-information/luggage/luggage-policy" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/KS.png" alt="ks" height="25%"/> <a href="http://www.penair.com/fly-with-us/baggage" rel="nofollow" target="_blank">PenAir &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="http://www.penair.com/fly-with-us/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.penair.com/fly-with-us/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.penair.com/fly-with-us/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.penair.com/fly-with-us/baggage" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/7H.png" alt="7h" height="25%"/> <a href="https://www.flyravn.com/flying-with-ravn/baggage-information/" rel="nofollow" target="_blank">Ravn Alaska &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.flyravn.com/flying-with-ravn/baggage-information/" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.flyravn.com/flying-with-ravn/baggage-information/" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.flyravn.com/flying-with-ravn/baggage-information/" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.flyravn.com/flying-with-ravn/baggage-information/" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/3M.png" alt="3m" height="25%"/> <a href="http://www.gosilver.com/more-information/travel-policies/policy/optional-fees" rel="nofollow" target="_blank">Silver Airways &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="http://www.gosilver.com/more-information/travel-policies/policy/optional-fees" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.gosilver.com/more-information/travel-policies/policy/optional-fees" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.gosilver.com/more-information/travel-policies/policy/optional-fees" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.gosilver.com/more-information/travel-policies/policy/optional-fees" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/9X.png" alt="9x" height="25%"/> <a href="https://iflysouthern.com/faq/" rel="nofollow" target="_blank">Southern Airways &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://iflysouthern.com/faq/" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://iflysouthern.com/faq/" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://iflysouthern.com/faq/" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://iflysouthern.com/faq/" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/O2.png" alt="o2" height="25%"/> <a href="http://www.linearair.com/terms" rel="nofollow" target="_blank">Linear Air Taxi &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="http://www.linearair.com/terms" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.linearair.com/terms" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.linearair.com/terms" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="http://www.linearair.com/terms" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>
                    <tr>
                      <td><img src="https://imgfolders.com/farehutz/WN.png" alt="wn" height="25%"/> <a href="https://www.southwest.com/html/customer-service/baggage/?int=GFOOTER-CUSTOMER-BAGGAGE-POLICIES" rel="nofollow" target="_blank">Southwest Airlines &nbsp;<span class=" view_policy_xs">View Policy</span> </a> </td>
                      <td><a href="https://www.southwest.com/html/customer-service/baggage/?int=GFOOTER-CUSTOMER-BAGGAGE-POLICIES" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.southwest.com/html/customer-service/baggage/?int=GFOOTER-CUSTOMER-BAGGAGE-POLICIES" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.southwest.com/html/customer-service/baggage/?int=GFOOTER-CUSTOMER-BAGGAGE-POLICIES" rel="nofollow" target="_blank">View Policy</a></td>
                      <td><a href="https://www.southwest.com/html/customer-service/baggage/?int=GFOOTER-CUSTOMER-BAGGAGE-POLICIES" rel="nofollow" target="_blank">View Policy</a></td>
                      </tr>

</tbody>
</table>


</body>
<Footer dealType={'Flights'} />
</>
  )
}
